<template>
    <!-- Login Modal -->
        <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-6">
                <div class="registration">
                    <div class="card">
                        <div class="card-body">
                            
                                <span class="shape"></span>
                                <div class="row" v-if="errMsgFlag">
                                    <div class="col-lg-12">
                                        <div class="alert alert-danger h5 bg-danger text-light p-2 alert-dismissible fade show" role="alert">{{errMsg}}</div>
                                    </div>
                                </div>
                                <div class="row" v-if="msgFlag">
                                    <div class="col-lg-12">
                                            <div class="alert alert-success h5 bg-success text-light p-2  alert-dismissible fade show" role="alert">{{msg}}</div>
                                    </div>
                                </div>
                            
                                <form class="row p-5" @submit.prevent v-if="userCheckForm">
                                    <div class="col-lg-12 form-group text-center">
                                        <h3>Forgot Password</h3>
                                    </div>
                                       <div class="form-group col-lg-12">
                                        <label for="">Phone or email address <span class="required-star"></span></label>
                                        <input type="text" class="form-control shadow-none" placeholder="Enter phone Or email" v-model="userName" >
                                    </div>
                            
                                    <div class="form-group col-lg-12">
                                        <div class="row no-gutters">
                                            <div class="col-lg-6">
                                                
                                            </div>
                                            <div class="col-lg-6 text-right">
                                               <a href="#"  data-bs-toggle="modal" data-bs-target="#staticBackdrop">Sign In Now</a>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="form-group col-lg-12">
                                        <button type="button" class="btn btn-success rounded-pill" @click="userCheck()">Submit</button>
                                    </div>
                                    
                                    <div class="form-group col-lg-12 mt-5 text-center">
                                        <p>You don't have account?
                                            <router-link :to="{name:'Registration'}">Registration</router-link>
                                        </p>
                                    </div>
                                </form>
                               <form  class="row p-5"  @submit.prevent="formSubmitPrevent()" v-if="otpForm">
                                <div class="form-group">
                                    <label >Phone or Email Address:</label>
                                    <label class="form-control shadow-none">{{userName}}</label>
                                </div>

                                <div class="form-group">
                                    <label for="">One Time Password <span class="required-star"></span></label>
                                    <input type="password" class="form-control shadow-none" placeholder="Enter One Time Password" v-model="otp" >
                                </div>

                                <div class="form-group">
                                    <div class="row no-gutters">
                                        <div class="col-lg-6">
                                        <slot v-if="countDown>0">
                                            <a href="#" class="text-danger">Resend OTP  in {{ countDown }}s</a>
                                        </slot>
                                        <slot v-else>
                                            <a href="#" @click="userCheck()">Resend OTP </a>
                                        </slot>
                                        </div>
                                        <div class="col-lg-6 text-right">
                                            <a href="#"@click="userCheckForm=true;otpForm=false;errMSgFlag=false;msgFlag=false;otp=null;">Retype Phone Or Email</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-success rounded-pill" @click="otpCheck()">Verify</button>
                                </div>
                                
                                <div class="form-group mt-5 text-center">
                                    <p>You don't have account?
                                        <router-link :to="{name:'Registration'}">Registration</router-link>
                                    </p>
                                </div>
                            </form>
                            <form  class="row p-5"  @submit.prevent="formSubmitPrevent()" v-if="passwordForm">
                                <div class="form-group">
                                    <label for="">New Password <span class="required-star"></span></label>
                                    <input type="password" class="form-control shadow-none" placeholder="Enter New Password" v-model="password" >
                                </div>
                                <div class="form-group">
                                    <label for="">Confirm Password <span class="required-star"></span></label>
                                    <input type="password" class="form-control shadow-none" placeholder="Confirm Password" v-model="conPassword" >
                                </div>
                        
                                <div class="form-group">
                                    <div class="row no-gutters">
                                        <div class="col-lg-6">
                                            
                                        </div>
                                        <div class="col-lg-6 text-right">
                                            <a href="#"  data-bs-toggle="modal" data-bs-target="#staticBackdrop">Sign In Now</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-success rounded-pill" @click="changePassword()">Change Password</button>
                                </div>
                                
                                <div class="form-group mt-5 text-center">
                                    <p>You don't have account?
                                        <router-link :to="{name:'Registration'}">Registration</router-link>
                                    </p>
                                </div>
                            </form>
                               
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3"></div>
        </div>
    <!-- /Login Modal -->
</template>

<script>
export default{
    data(){
        return{
            userCheckForm:true,
            otpForm:false,
            passwordForm:false,
            otp:null,
            password:null,
            conPassword:null,
            userName:null,
            errMsg:null,
            errMsgFlag:false,
            msg:null,
            msgFlag:false,
            countDown : 120,
        }
    },
    methods:{
        formReset(){
            this.userName=null;
            this.password=null;
            this.conPassword=null;
            this.otp=null;
        },
        passwordFormValidation(){
            if(this.password!=null && this.conPassword!=null){
                if(this.password==this.conPassword){
                    return true;
                }
                else{
                    this.errMsg="Please Confirm New Password.";
                    this.$toaster.warning(this.errMsg);
                    this.errMsgFlag=true;
                    return false;
                }
            }
            else{
                if(this.password==null){
                    this.errMsg="Please Enter A Password.";
                    this.$toaster.warning(this.errMsg);
                }
                if(this.conPassword==null){
                    this.errMsg="Confirm Password.";
                    this.$toaster.warning(this.errMsg);
                }
                this.errMsgFlag=true;
                return false;
            }
        },
        changePassword(){
            this.errMsgFlag=false;
            this.msgFlag=false;
            let formData=new FormData();
            formData.append("userName",this.userName);
            formData.append("password",this.password);
            formData.append("conPassword",this.conPassword);
            formData.append("otp",this.otp);
            if(this.passwordFormValidation()){
                this.$http.post("reset/password",formData)
                 .then(response=>{
                    this.errMsgFlag=response.data.errMsgFlag;
                    this.msgFlag=response.data.msgFlag;
                    this.errMsg=response.data.errMsg;
                    this.msg=response.data.msg;
                    if(response.data.errMsgFlag==true)
                        this.$toaster.warning(this.errMsg);
                    if(response.data.msgFlag==true){
                        this.$toaster.success(this.msg);
                        this.formReset();
                        // this.$router.push({name:'customer.signin'});
                    }
                 })
                 .catch(error=>{console.dir(error)})
                 .finally();
            }
        },
        otpCheck(){
            this.errMsgFlag=false;
            this.msgFlag=false;
            let formData=new FormData();
            formData.append("userName",this.userName);
            formData.append("otp",this.otp);
            
            this.$http.post("opt/check/to/reset/password",formData)
             .then(response=>{
                if(response.data.msgFlag==true){
                    this.msgFlag=response.data.msgFlag;
                    this.msg=response.data.msg;
                    this.$toaster.success(this.msg);
                    this.otpForm=false;
                    this.passwordForm=true;
                    
                }
                else{
                    this.errMsgFlag=response.data.errMsgFlag;
                    this.errMsg=response.data.errMsg;
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        },
        userCheck(){
            this.errMsgFlag=false;
            this.msgFlag=true;
            this.msg='Please Wait A Moment.Loading...';
            let formData=new FormData();
            formData.append("userName",this.userName);
            
            this.$http.post("opt/sent/for/password/reset",formData)
             .then(response=>{
                if(response.data.msgFlag==true){
                    this.msgFlag=response.data.msgFlag;
                    this.msg=response.data.msg;
                    this.$toaster.success(this.msg);
                    this.userCheckForm=false;
                    this.otpForm=true;
                    this.countDown=120;
                    this.countDownTimer();
                }
                else{
                    this.errMsgFlag=response.data.errMsgFlag;
                    this.errMsg=response.data.errMsg;
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        },
        countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
            }
    }
}
</script>